<template>
    <div class="target-calendar-body">
        <v-overlay :model-value="loading" contained persistent
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="target-calendar-controls">
            <div v-if="!simpleOnly" class="target-header">
                <v-icon icon="mdi-bullseye-arrow" class="mr-1"></v-icon> Scheduled: <small style="color: #BDBDBD ">{{ dateHeader }}</small>
            </div>
            <div class="target-controls">
                <v-btn variant="outlined" @click="moveToday">
                    TODAY
                </v-btn>
                <v-btn variant="outlined" prepend-icon="mdi-arrow-left-circle-outline" class="ml-2" @click="previousPeriod">
                    PREVIOUS
                </v-btn>
                <v-btn variant="outlined" prepend-icon="mdi-arrow-right-circle-outline" class="ml-2" @click="nextPeriod">
                    NEXT
                </v-btn>
            </div>
        </div>
        <table v-if="days.length > 0 && data" class="calendar-view">
            <tr class="calendar-day-row">
                <th></th>
                <th v-for="day in days" :key="day" class="day-part" :class="{'today-calendar-date':day.isSame($dayjs(), 'day')}">{{ day.format('ddd D/M') }}</th>
            </tr>
            <tr class="calendar-line-row">
                <td>Line 1</td>
                <td v-for="(day, index) in days" :key="day" class="day-part" :class="{ 'over-budget': data[index].line1Percentage > 100 }">
                    <div class="progress" :style="{ 'width' : (data[index].line1Percentage > 100 ? 100 : data[index].line1Percentage)+'%' }"></div>
                    <div class="progress-percentage">${{ numberWithCommas(data[index].line1Dollar/1000, 0) }}</div>
                </td>
            </tr>
            <tr class="calendar-line-row">
                <td>Line 2</td>
                <td v-for="(day, index) in days" :key="day" class="day-part">
                    <div class="progress" :style="{ 'width' : (data[index].line2Percentage > 100 ? 100 : data[index].line2Percentage)+'%' }"></div>
                    <div class="progress-percentage">${{ numberWithCommas(data[index].line2Dollar/1000, 0) }}</div>
                </td>
            </tr>
            <tr v-if="!simpleOnly" class="calendard-total-row">
                <td>TOTAL</td>
                <td v-for="(day, index) in days" :key="day" class="day-part" :class="{ 'over-budget': data[index].totalPercentage > 100 }">
                    <div class="progress" :style="{ 'width' : (data[index].totalPercentage > 100 ? 100 : data[index].totalPercentage)+'%' }"></div>
                    <div class="progress-percentage">${{ numberWithCommas(data[index].totalDollar/1000, 0) }}</div>
                </td>
            </tr>
        </table>
    </div>
</template>
<style scoped>
    .progress {
        background-color: #FFF9C4;
        height: 100%;
        border-right: #FFF176 1px solid;
    }
    .over-budget .progress {
        background-color: #C8E6C9 !important;
        border-right: #43A047 1px solid!important;
    }
    .progress-percentage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
    }
    .target-calendar-body {
        width: 100%;
        height: 100%;
        padding: 5px;
        position:relative;
    }
    .target-calendar-controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .target-header {
        font-size: 25px;
        font-weight: bold;
    }
    .calendar-view {
        margin-top:10px;
        width:100%;
        border-collapse:collapse;
    }
    .calendar-day-row th {
        border-bottom: thin solid #BDBDBD;
        height: 15px;
    }
    .calendar-line-row td {
        border-bottom: thin solid #BDBDBD;
        height: 39px;
    }
    .day-part {
        width: 6.5%;
        border-left: thin solid #BDBDBD;
        padding: 0px;
        position: relative;
        background: white;
    }
    .calendar-day-row .day-part{
        background: none;
    }
    .calendard-total-row td {
        border-top: 2px solid #212121;
        height: 35px;
    }
    .calendard-total-row {
        font-weight:bold;
    }
    .today-calendar-date {
        background-color: #D32F2F !important;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false,
            startDay: null,
            endDay: null,
            days: [],
            data:null
        };
    }
    import { mapActions, mapState, mapMutations } from "vuex";
    import { numberWithCommas } from "../mixins/numberMixins";
    export default {
        name: "TargetCalendar",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.startDay = this.$dayjs();
            if (this.date) {
                this.startDay = this.$dayjs(this.date);
            }
            this.buildDays();
        },
        props: {
            openView: Boolean,
            simpleDisplay: Boolean,
            date: String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            numberWithCommas,
            buildDays(){
                let startDate = this.startDay.startOf('week').add(1, 'day');
                this.startDay = startDate.clone();
                // Get next week's Sunday
                let endDate = startDate.add(13, 'days');
                // Create an array to hold the dates
                this.days = [];
                // Loop from startDate to endDate
                while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'day')) {
                    this.days.push(startDate); // Format the date as needed
                    startDate = startDate.add(1, 'day');
                }
                this.endDay = startDate.clone().subtract(1, 'day');
                if (this.simpleOnly) {
                    this.loadData();
                }
            },
            loadData() {
                this.loading = true;
                this.$api.get('/dashboard/budgetcalendar?startDate='+this.startDay.format('YYYY-MM-DD')+"&endDate="+this.endDay.format('YYYY-MM-DD'))
                    .then(res => {
                        this.loading = false;
                        this.data = res.data;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the budget calendar data");
                    });
            },
            moveToday() {
                this.startDay = this.$dayjs();
                this.buildDays();
                this.loadData();
            },
            previousPeriod() {
                this.startDay = this.startDay.subtract(14, 'day');
                this.buildDays();
                this.loadData();
            },
            nextPeriod() {
                this.startDay = this.startDay.add(14, 'day');
                this.buildDays();
                this.loadData();
            },
        },
        computed: {
            dateHeader() {
                if (this.startDay) {
                    return this.startDay.format('D MMMM YYYY')+" - "+this.endDay.format('D MMMM YYYY');
                }
                return '';
            },
            simpleOnly() {
                return this.simpleDisplay == true;
            }
        },
        watch: {
            openView: {
                handler() {
                    if (this.openView){
                        this.loadData();
                    }
                }
            },
            date: {
                handler() {
                    if (this.date) {
                        this.startDay = this.$dayjs(this.date);
                        this.buildDays();
                    }
                }
            }
        }
    };
</script>