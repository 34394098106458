<template>
    <v-dialog v-model="showBulkSchedule"
            persistent
            scrollable
            min-width="95%"
            min-height="90%"
            width="auto">
        <v-card>
            <v-toolbar theme="dark">
                <v-toolbar-title>Bulk Schedule Update</v-toolbar-title>
                <v-btn icon="mdi-close"
                       @click="showBulkSchedule = false"></v-btn>
            </v-toolbar>
            <v-card-text>
                <div class="d-flex justify-space-between">
                    <div>
                        <p>What are you wanting to update?</p>
                        <v-radio-group v-model="mode" inline>
                            <v-radio label="Target Completion Date" value="target"></v-radio>
                            <v-radio label="Date Received" value="received"></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="pt-4">
                        <v-text-field type="date"
                                      label="Bulk Update Date"
                                      density="compact"
                                      :hint="noneSelected ? 'Select at least one job below': ''"
                                      v-model="bulkUpdateDate"></v-text-field>
                    </div>
                    <div class="pt-5">
                        <v-btn color="primary"
                               :disabled="bulkErrors.length > 0"
                               @click="bulkUpdate">
                            BULK UPDATE
                        </v-btn>
                    </div>
                    <div>
                        <v-alert icon="mdi-alert-decagram-outline"
                                 class="my-2"
                                 v-if="bulkErrors.length > 0"
                                 type="warning">
                            <ul class="small-text">
                                <li :key="error" v-for="error in bulkErrors">{{ error }}</li>
                            </ul>
                        </v-alert>
                    </div>
                </div>
                <v-table class="w-100">
                    <thead>
                        <tr>
                            <th class="w-5">
                                <v-btn color="primary"
                                       @click="selectAll"
                                       size="x-small"><span v-if="allSelected">UNSELECT</span><span v-if="!allSelected">SELECT</span> ALL</v-btn>
                            </th>
                            <th class="w-10">#</th>
                            <th class="w-10">Customer</th>
                            <th class="w-10">Cust#</th>
                            <th class="w-10 pl-2">Received</th>
                            <th class="w-10">Target</th>
                            <th class="w-10">Line</th>
                            <th class="w-10">Schedule</th>
                        </tr>
                    </thead>
                    <tbody v-for="job in bulkUpdateJobs" :key="job.id">
                        <tr>
                            <td>
                                <v-checkbox :hide-details="true" v-model="job.bulkSelect"></v-checkbox>
                            </td>
                            <td>
                                <job-link :job="job"></job-link>
                            </td>
                            <td><contact-link :contact="job.contact"></contact-link></td>
                            <td>{{ job.customerNumber }}</td>
                            <td class="pl-2" :class="{'bg-orange-lighten-3' : !job.dateReceived}">
                                <v-text-field type="date"
                                              density="compact"
                                              variant="plain"
                                              :hide-details="true"
                                              @update:modelValue="newValue => job.dateReceived = newValue"
                                              :model-value="dateValue(job.dateReceived)"></v-text-field>
                            </td>
                            <td :class="{'bg-orange-lighten-3' : !job.dateTargetCompletion}">
                                <v-text-field type="date"
                                              density="compact"
                                              variant="plain"
                                              :hide-details="true"
                                              @update:modelValue="newValue => job.dateTargetCompletion = newValue"
                                              :model-value="dateValue(job.dateTargetCompletion)"></v-text-field>
                            </td>
                            <td>
                                <v-select v-model="job.newScheduleLine"
                                            item-title="text"
                                            item-value="value"
                                            persistent-hint
                                            :class="{'invalidSchedule' : job.newScheduleDate && !job.newScheduleLine }"
                                            :hint="job.newScheduleDate && !job.newScheduleLine ? 'Schedule line is required' : ''"
                                            :items="[{text:'', value:null}, ...$constants.jobs.lines]"></v-select>
                            </td>
                            <td>
                                <v-checkbox :hide-details="true" v-model="job.showSchedule"></v-checkbox>
                            </td>
                        </tr>
                        <tr v-if="job.showSchedule">
                            <td colspan="8">
                                <div class="d-flex justify-space-between">
                                    <v-sheet class="mt-2 pa-2" color="grey-lighten-3" rounded>
                                        <div class="d-flex justify-space-between">
                                            <v-text-field type="date"
                                                          label="Receive Date"
                                                          density="compact"
                                                          variant="plain"
                                                          @update:modelValue="newValue => job.dateReceiveEstimate = newValue"
                                                          :model-value="dateValue(job.dateReceiveEstimate)"></v-text-field>
                                            <v-text-field type="date"
                                                          label="Required By Date"
                                                          density="compact"
                                                          variant="plain"
                                                          disabled
                                                          :model-value="dateValue(job.dateTargetCompletion)"></v-text-field>
                                        </div>
                                        <i>Setting the estimated receive date and required by date will show the current scheduled capacity between these dates.</i>
                                    </v-sheet>
                                    <v-sheet class="mt-2 pa-2" color="yellow-lighten-3" rounded>
                                        <v-text-field type="date"
                                                      density="compact"
                                                      variant="plain"
                                                      persistent-hint
                                                      label="Schedule Date"
                                                      :hint="!job.newScheduleDate && job.newScheduleLine ? 'Schedule date is required' : ''"
                                                      :class="{'invalidSchedule' : !job.newScheduleDate && job.newScheduleLine }"
                                                      @update:modelValue="newValue => job.newScheduleDate = newValue"
                                                      :model-value="dateValue(job.newScheduleDate)"></v-text-field>
                                        <i>This is the date the job will be scheduled for</i>
                                    </v-sheet>
                                </div>
                                <div>
                                    <target-calendar :simple-display="true" :open-view="true" :date="job.dateReceiveEstimate"></target-calendar>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="printAll"
                       prepend-icon="mdi-printer"
                       color="info">
                    PRINT ALL
                </v-btn>
                <v-btn @click="saveAll"
                       :loading="saving"
                       prepend-icon="mdi-check-circle"
                       color="success">
                    SAVE ALL
                </v-btn>
                <v-btn @click="showBulkSchedule = false" :disabled="saving">
                    CANCEL
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss';

    .invalidSchedule {
        border:1px solid red;
        border-radius:5px;
        padding:3px;
    }
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import jobs from "../../../constants/jobs";
    import { formatSearchName } from "../../../mixins/searchMixins";
    import { numberWithCommas } from "../../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            mode: '',
            bulkUpdateDate: ''
        };
    }
    export default {
        name: "SmartBulkScheduleDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "refreshSmartData"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            numberWithCommas,
            resetInput() {
            },
            dateValue(date) {
                if (date){
                    return this.$dayjs(date).format('YYYY-MM-DD');
                }
                return null;
            },
            printAll() {
                this.$confirm(
                    {
                        message: `This will print all ${this.bulkUpdateJobs.length} jobs listed? Are you sure?`,
                        disableKeys: false,
                        auth: false,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: confirm => {
                            if (confirm) {
                                var ids = this.bulkUpdateJobs.map(job => job.id).join(',');
                                const routeData = this.$router.resolve({ name: 'jobsheetlist', query: { ids: ids } });
                                window.open(routeData.href, '_blank');
                            }
                        }
                    }
                )
            },
            selectAll() {
                var updateValue = !this.allSelected;
                this.bulkUpdateJobs.forEach(job => {
                    job.bulkSelect = updateValue;
                });
            },
            bulkUpdate() {
                this.selectedBulk.forEach(job => {
                    if (this.mode == 'target') {
                        job.dateTargetCompletion = this.bulkUpdateDate;
                    } else if (this.mode == 'received') {
                        job.dateReceived = this.bulkUpdateDate;
                    }
                });
                this.bulkUpdateDate = '';
                this.mode = '';
                this.bulkUpdateJobs.forEach(job => {
                    job.bulkSelect = false;
                });
            },
            saveAll() {
                this.saving = true;
                let formData = [];
                let i = 0;
                this.bulkUpdateJobs.forEach(job => {
                    let patches = [
                        { op: 'replace', path: '/dateTargetCompletion', value: job.dateTargetCompletion },
                        { op: 'replace', path: '/dateReceived', value: job.dateReceived },
                    ];
                    formData.push({
                        id: job.id, Patch: patches, ScheduleStatus: job.newScheduleLine, ScheduleDate: job.newScheduleDate
                    });
                });
                this.$api.patch('/jobs/bulk', formData)
                    .then(res => {
                        this.showSuccessSnack('Jobs bulk updated!');
                        this.saving = false;
                        this.showBulkSchedule = false;
                        this.refreshSmartData();
                    }).catch(error => {
                        this.showErrorSnack('There was an issue bulk updating the jobs.')
                        this.saving = false;
                    });
            }
        },
        computed: {
            ...mapGetters([
                'smartScheduleJobs',
            ]),
            bulkUpdateJobs: {
                get() {
                    return this.$store.state.smart.bulkUpdateJobs;
                },
                set(value) {
                    this.$store.commit('setBulkUpdateJobs', value)
                }
            },
            showBulkSchedule: {
                get() {
                    return this.$store.state.smart.showBulkSchedule
                },
                set(value) {
                    this.$store.commit('setShowBulkSchedule', value)
                }
            },
            selectedBulk() {
                if (this.bulkUpdateJobs && this.bulkUpdateJobs.length > 0) {
                    return this.bulkUpdateJobs.filter(job => job.bulkSelect == true);
                }
                return [];
            },
            allSelected() {
                if (this.bulkUpdateJobs && this.bulkUpdateJobs.length > 0) {
                    return this.bulkUpdateJobs.filter(job => job.bulkSelect == false || !job.bulkSelect).length == 0;
                }
                return false;
            },
            noneSelected() {
                if (this.bulkUpdateJobs && this.bulkUpdateJobs.length > 0) {
                    return this.bulkUpdateJobs.filter(job => job.bulkSelect == true).length == 0;
                }
                return true;
            },
            bulkErrors() {
                let errors = [];
                if (!this.mode || this.mode.length == 0) {
                    errors.push('Need to know what you are bulk updating.')
                }
                if (!this.bulkUpdateDate || this.bulkUpdateDate.length == 0) {
                    errors.push('Need bulk update date.')
                }
                if (this.noneSelected) {
                    errors.push('Need at least one job to bulk update.')
                }
                return errors;
            },
        },
        watch: {
        }
    };
</script>